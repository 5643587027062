import { Link } from '@remix-run/react'
import type { Vendor } from '@repo/db/schema'
import { Badge } from '@repo/ui/components/Badge.js'
import { Separator } from '@repo/ui/components/Separator.js'
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger
} from '@repo/ui/components/Tooltip.js'
import { VendorIcon } from './VendorIcon'

export const VendorBadge = ({
  name,
  vendorId,
  iconUrl,
  tagline,
  to
}: {
  name: Vendor['name']
  vendorId: Vendor['id']
  iconUrl: Vendor['iconUrl']
  tagline: Vendor['tagline']
  to?: string
}) => {
  return (
    <Tooltip delayDuration={1000}>
      <TooltipTrigger>
        <Link to={to || `/apps/${vendorId}`} title={name} prefetch="intent">
          <Badge className="gap-1.5 text-sm font-medium hover:underline" variant="slate">
            <VendorIcon className="size-4" src={iconUrl} />
            <span className="max-w-48 truncate">{name}</span>
          </Badge>
        </Link>
      </TooltipTrigger>
      <TooltipContent className="max-w-72">
        <TooltipArrow />
        <div className="space-y-2">
          <div className="flex items-center gap-1.5">
            <VendorIcon className="size-4" src={iconUrl} />
            <span className="max-w-60 truncate font-medium">{name}</span>
          </div>
          <Separator />
          <p className="text-muted-foreground text-xs text-pretty">{tagline}</p>
        </div>
      </TooltipContent>
    </Tooltip>
  )
}
